import classNames from 'classnames';
import React from 'react';
import Tag from '.';
import { tagGroupStyle } from './style';
import { TTagOption } from './type';

interface ITagGroupProps {
  options: TTagOption[];
  className?: string;
  onClickDelete?: (value: any) => void;
}

function TagGroup({ className, options = [], onClickDelete = () => null }: ITagGroupProps) {
  return (
    <ul css={tagGroupStyle} className={classNames(className, 'tag-lists')}>
      {options.map((option, index: number) => (
        <React.Fragment key={index}>
          <Tag onClickDelete={onClickDelete} {...option}></Tag>
        </React.Fragment>
      ))}
    </ul>
  );
}

export default TagGroup;
