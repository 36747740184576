import React from 'react';
import classNames from 'classnames';
import badgeStyle from './style';

const Badge = ({ className, color, fontColor, outline = false, children, ...otherProps }: IBadgeProps) => {
  return (
    <span
      css={badgeStyle(color, fontColor)}
      className={classNames(className, 'badge badge-util', { outline: outline })}
      {...otherProps}
    >
      {children}
    </span>
  );
};

export default Badge;
