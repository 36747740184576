import { css } from '@emotion/react';
import { colors, flex, font } from 'styles/mixin';

export const tagGroupStyle = css`
  &.tag-lists {
    ${flex('row', 'flex-start', 'center')};
    margin: -0.4rem -0.2rem;
  }
`;

const tagStyle = css`
  .badge-util {
    padding: 0px;
    background-color: transparent !important;
    color: transparent !important;

    &.outline {
      border: 1px solid transparent;
      background-color: transparent;
      color: transparent;
    }
  }

  &.tag-list {
    margin: 0.4rem 0.2rem;

    .tag {
      ${flex('row', 'flex-start', 'center')};
      padding: 0.5rem 1.1rem 0.3rem;
      border-radius: 1.6rem;
      border: 1px solid ${colors.$color_999};
      ${font('1.3rem', 400, colors.$color_999, '2rem')};
      white-space: nowrap;
      &.tag-del-type {
        padding: 0.3rem 0.9rem;
        border-color: ${colors.$secondary1};
        background-color: ${colors.$color_fff};
        color: ${colors.$secondary1};
      }

      &.active {
        border-color: ${colors.$secondary1};
        background-color: ${colors.$secondary1};
        color: ${colors.$color_fff};

        .ic {
          &.ic-tag-close {
            background: url('/assets/images/ic_tag_close_sel.svg') no-repeat center / 1.6rem;
          }
        }
      }

      &:disabled {
        border-color: ${colors.$color_cbced1} !important;
        color: ${colors.$color_cbced1} !important;
      }

      .ic {
        margin-left: 0.2rem;
      }
    }
  }
`;

export default tagStyle;
