import { css } from '@emotion/react';
import { flex, layout } from 'styles/mixin';

const BannerStyle = css`
  overflow: hidden;
  position: relative;
  &.bottom {
    margin-top: 12rem;
    margin-bottom: -12rem;
  }

  .banner-link {
    display: block;
    background-color: #fff;
  }

  .swiper-pagination-container {
    z-index: 1;
    position: absolute;
    bottom: 1.2rem;
    left: 0;
    width: 100%;
    .swiper-pagination {
      .swiper-pagination-bullet {
        width: 0.8rem;
        height: 0.8rem;
        // display: inline-block;
        &:not(:first-of-type) {
          margin-left: 1rem;
        }
      }
    }
  }

  @media ${layout.$tablet} {
    margin: 0;
    &.bottom {
      margin-top: 12rem;
    }
  }

  @media ${layout.$mobile} {
    &.bottom {
      margin: 0;
    }
  }
`;

export default BannerStyle;
