import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const filterButtonStyle = css`
  ${flex('row', 'flex-start', 'center')};
  position: relative;
  padding-right: 2.8rem;
  ${font('1.6rem', 400, colors.$color_000, '2rem')};
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 2.4rem;
    height: 2.4rem;
    background: url('/assets/images/ic_dropdown_arrow.svg') no-repeat center / 2.4rem;
    transform: translateY(-50%);
  }
  &.active {
    &::after {
      transform: translateY(-50%) rotate(180deg);
    }
  }
  .ic {
    margin-right: 0.8rem;
  }

  @media ${layout.$mobile} {
    &.btn-filter {
      padding: 0;
      &::after {
        display: none;
      }
      .ic {
        margin: 0;
      }
    }
  }
`;

export default filterButtonStyle;
