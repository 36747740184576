import QuickMenu from '@/components/modules/quickMenu';
import useMenuLists from '@/hooks/useMenuList';
import { useCategories } from '@/query/category/query';
import { compareSelector } from '@/stores/compare';
import { isDefaultfilterSelector, selectedModelFilterSelector, vehicleListSelector } from '@/stores/ctb';
import { makeImageKey } from '@utils/convert';
import { defaultServerSideProps } from '@utils/defaultServerSideProps';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import Banner from './index/components/banner';
import FilterButton from './index/components/filterButton';
import ModelFilter from './index/components/modelFilter';
import ctbMainStyle from './index/style';
import { useGetContentImages } from '@/query/content/query';
import useDevelopInfo from '@/hooks/useDevelopInfo';
import { ALL_CATEGORY_CODE, PRICE_RANGE_MAX, PRICE_RANGE_MIN } from './index/constants';
import VehicleList from './index/components/vehicleList';
import Tab from '@/components/haeLibraryRefactored/Tab';
import { gaAttributes } from '@/utils/gaCommon';
import { currentConsultantState, currentDealerState, memberCheckState } from '@/stores/common/user';

const Home: NextPage = () => {
  useDevelopInfo();
  const router = useRouter();
  const menuLists = useMenuLists();
  const ref = useRef<any>(null);
  const { t } = useTranslation();

  const [category, setCategory] = useState<Main.IDisplayCategory | null>(null);
  const vehicleList = useRecoilValue(vehicleListSelector);
  const currentDealer = useRecoilValue(currentDealerState);
  const currentConsultant = useRecoilValue(currentConsultantState);
  const isMemberChecked = useRecoilValue(memberCheckState);
  const [selectedModelFilterState, setSelectedModelFilterState] =
    useRecoilState<Partial<Main.TModelFilterState>>(selectedModelFilterSelector);
  const isDefaultFilter = useRecoilValue(isDefaultfilterSelector);
  const compareStore = useRecoilValue(compareSelector);

  const { data: dataImages, isFetching } = useGetContentImages({
    contentType: 'products',
    imageType: 'listing',
    imageKeys: [
      ...new Set(
        vehicleList
          ?.filter(vehicle => vehicle?.baseExteriorColorId && vehicle?.baseVariantId)
          ?.map(vehicle => makeImageKey([vehicle?.baseVariantId, vehicle?.baseExteriorColorId])),
      ),
    ],
  });

  const { cartItems } = compareStore;
  const currentVehicleList = useMemo(() => vehicleList ?? [], [dataImages]);

  const listSource =
    (!isFetching ? vehicleList : currentVehicleList)?.map((item: any) => ({
      ...item,
      imgUrl:
        dataImages?.images.find(
          (d2: Main.IVehicleImage) =>
            item?.baseVariantId &&
            item?.baseExteriorColorId &&
            d2?.imageKey === `${makeImageKey([item?.baseVariantId, item?.baseExteriorColorId])}`,
        )?.imageValues?.[0]?.url || '/assets/images/thum_vehicle.png',
    })) ?? [];

  const { type } = router.query;

  useEffect(() => {
    if (!ref?.current || !type || !isMemberChecked || !currentDealer) return;

    const camelType: Partial<Record<TKebabQuickModalType, TQuickModalType>> = {
      'trade-in': 'tradeIn',
      'test-drive': 'testDrive',
      'contact-us': 'contactUs',
      quote: 'quote',
      'service-appointment': 'serviceAppointment',
      'ioniq-basic': 'ioniqBasic',
      'ioniq-one-day': 'ioniqOneDay',
      'ioniq-long-distance': 'ioniqLongDistance',
      'ioniq-lab': 'ioniqLab',
    };

    const modalType = camelType[type as TKebabQuickModalType];

    if (!modalType) return;

    if (ref?.current?.getIsQuickPath()) ref?.current?.openQuickMenuModal(modalType);
  }, [ref, isMemberChecked, currentDealer]);

  const { data: dataCategories } = useCategories();

  useEffect(() => {
    !isDefaultFilter && setCategory(null);
  }, [selectedModelFilterState]);

  const handleTabClick = (key: string | number) => {
    const data = dataCategories.find(d => d?.categoryCode === key) ?? null;

    if (data?.categoryCode === category?.categoryCode) return;

    if (key !== ALL_CATEGORY_CODE)
      setSelectedModelFilterState({ maxPriceRange: PRICE_RANGE_MAX, minPriceRange: PRICE_RANGE_MIN });

    setCategory(data);
  };

  const tabItems = dataCategories?.reduce(
    (acc, data) => {
      const { categoryDisplayName: label, categoryCode: key } = data;

      acc.push({ label, key });

      return acc;
    },
    [{ label: 'ALL', key: ALL_CATEGORY_CODE }],
  );

  return (
    <div className="common-content-container ctb ctb-models" css={ctbMainStyle}>
      <Banner displayPagePolicy={'SELECT_A_MODEL'} displayArea="TOP" />
      <div className="select-model-container">
        <div className="select-model-area">
          <div className="select-model-top">
            <div className="title-box">
              <span className="title">{t(`Label.${isDefaultFilter ? 'SelectAModel' : 'SelectAVariant'}`)}</span>
            </div>
          </div>
          <div className="select-model-middle">
            <div className="select-model-middle-top">
              <div className="select-model-middle-top-left">
                <Tab
                  data={tabItems}
                  onChange={handleTabClick}
                  activeKey={category?.categoryCode ?? ALL_CATEGORY_CODE}
                  {...gaAttributes({ action: 'menu', label: category })}
                />
              </div>
              <div className="select-model-middle-top-right">
                <FilterButton />
              </div>
            </div>
            <div className="select-model-middle-bottom">
              <ModelFilter category={category} />
            </div>
          </div>
          <div className="select-model-bottom">
            <div className="select-model-bottom-top">
              <div className="select-model-bottom-top-left">
                <div className="total-box">
                  <Trans
                    i18nKey="Label.Total_N_Vehicles"
                    tOptions={{ total: listSource?.length ?? 0 }}
                    components={{
                      count: <span className="total-num"></span>,
                    }}
                  />
                </div>
              </div>
              <div className="select-model-bottom-top-right">
                {/* Comment : .compare-box 클릭 시 하단 토스트 팝업 노출, .count 노출 */}
                <button
                  type="button"
                  className="btn-compare"
                  onClick={() => router.push(menuLists.compare.path)}
                  {...gaAttributes({ action: t('SelectAModel.CompareVehicles') })}
                >
                  <div className="compare-count-box">
                    <i className="ic ic-compare"></i>
                    <span className="count">{cartItems?.filter((d: ComparePage.ICartItems) => d)?.length}</span>
                  </div>
                  <span className="text">{t('SelectAModel.CompareVehicles')}</span>
                </button>
              </div>
            </div>
            <div className="select-model-bottom-bottom">
              <div className="vehicle-container">
                <VehicleList
                  listSource={listSource}
                  quickMenuRef={ref}
                  isDataFetched={!!(dataImages && vehicleList)}
                  hasVehicles={!!vehicleList?.length}
                />
              </div>
            </div>
          </div>
        </div>
        <QuickMenu ref={ref} />
      </div>
      <Banner displayPagePolicy={'SELECT_A_MODEL'} displayArea="BOTTOM" />
    </div>
  );
};

export const getServerSideProps = defaultServerSideProps;

export default Home;
