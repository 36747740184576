import { css } from '@emotion/react';
import { colors, ellipsis, flex, font, layout, multiEllipsis } from 'styles/mixin';

const vehicleListStyle = css`
  .vehicle-box {
    width: 35.2rem;
    width: calc(33.33% - 3.2rem);
    margin: 1.6rem;
    padding: 3rem 0;
    border: 1px solid ${colors.$color_e1e};
    transition: 0.15s box-shadow;
    @media (hover: hover) {
      &:hover {
        box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.05);
      }
    }
    &.selected {
      .btn-compare {
        .ic-compare {
          display: none;
        }
        .ic-compare-s {
          display: block;
        }
      }
    }
    .btn-compare {
      .ic-compare {
        display: block;
      }
      .ic-compare-s {
        display: none;
      }
    }
    .vehicle-box-top {
      ${flex('row', 'space-between', 'center')};
      padding: 0 3rem;
    }
    .vehicle-box-middle {
      ${flex('column', 'center', 'center')};
      width: 100%;
      margin-top: 2.4rem;
      padding: 0 3rem;
    }
    .vehicle-box-bottom {
      padding: 0 3rem;
    }
    .vehicle-box-middle-top {
      width: 100%;
      .text-box {
        ${flex('column', 'center', 'center')};
        width: 100%;
        .title-box {
          position: relative;
          width: 100%;
          height: 8.8rem;
          text-align: center;
          .desc {
            display: block;
            margin-bottom: 0.4rem;
            ${ellipsis()};
            ${font('1.3rem', 400, colors.$color_999, '2rem')};
          }
          .title {
            ${flex('row', 'center', 'center')};
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 6.4rem;
            ${font('2.6rem', 400, colors.$color_000, '3.2rem')};
            span {
              ${multiEllipsis(true, 2)};
              word-break: break-all;
            }
          }
        }
        .price-box {
          margin-top: 0.4rem;
          .text {
            ${font('1.4rem', 400, colors.$color_767, '2rem')};
          }
          .price {
            margin: 0 0 0 0.8rem;
          }
          .tooltip-box {
            margin-left: 0.4rem;
            .tooltip {
              left: -23.6rem;
              .ic-tooltip-tail {
                left: auto;
                right: 7.3rem;
              }
            }
          }
        }
        .electric-info-box {
          margin-top: 0.4rem;
          width: 80%;
          text-align: center;
          .text {
            ${font('1.2rem', 400, colors.$color_000, '2rem')};
          }
        }
      }
    }
    .vehicle-box-middle-middle {
      width: 100%;
      margin: 3.2rem 0;
      .image-box {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 14.8rem;
        img {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: auto;
          height: 100%;
        }
      }
    }
    .vehicle-box-middle-bottom {
      width: 100%;
      .btn {
        width: 100%;
        border: 1px solid ${colors.$color_e4e};
        ${font('1.6rem', 400, colors.$color_767, '2.4rem')};
        &:disabled {
          background-color: ${colors.$color_767};
          color: ${colors.$color_999};
        }
        @media screen and (min-width: 767px) and (hover: hover) {
          &:hover {
            border-color: ${colors.$primary};
            background-color: ${colors.$primary};
            color: ${colors.$color_fff};
          }
        }
      }

      display: grid;
      gap: 10px;
    }
    .vehicle-box-bottom {
      ${flex('row', 'center', 'center')};
      .btn {
        margin-top: 2rem;
      }
    }
  }

  @media ${layout.$tablet} {
    .data-list-swCustomUI {
      width: 100%;
    }
    .vehicle-box {
      width: calc(50% - 3.2rem);
    }
  }

  @media ${layout.$mobile} {
    .vehicle-box {
      width: 100%;
      margin: 0.8rem 0 0 0;
      padding: 2.4rem 0 4rem;
      border: none;
      background-color: ${colors.$color_fff};
      &:first-of-type {
        margin: 0;
      }
      .vehicle-box-top,
      .vehicle-box-middle,
      .vehicle-box-bottom {
        padding: 0 2rem;
      }
      .vehicle-box-middle-top {
        .text-box {
          .title-box {
            .desc {
              margin: 0;
            }
          }
          .price-box {
            margin-top: 1.6rem;
          }
        }
      }
    }
  }

  .vehicle-list-ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .No-vehicleList {
    width: 100%;

    .vehicle-box {
      margin: auto;
      margin-top: 100px;

      .vehicle-box-middle-top {
        display: none;
      }

      @media (hover: hover) {
        &:hover {
          box-shadow: none;
        }
      }

      @media ${layout.$mobile} {
        margin-top: auto;

        .vehicle-box-middle-top {
          display: block;
        }
      }
    }
  }
`;

export default vehicleListStyle;
