import ToastModal from '@/components/modules/modals/toastModal';
import { TQuickMenuModalHandle } from '@/components/modules/quickMenu/components/modal';
import { compareThreshold } from '@/constants/common';
import { globalAlertPropsState } from '@/stores/common';
import { currentDealerSelector } from '@/stores/common/user';
import { compareSelector } from '@/stores/compare';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import EmptyVehicleList from './components/emptyVehicleList';
import VehicleListItem from './components/vehicleListItem';
import vehicleListStyle from './style';

interface IVehicleListProps {
  listSource: Main.IVariant[];
  quickMenuRef: React.MutableRefObject<TQuickMenuModalHandle>;
  isDataFetched: boolean;
  hasVehicles: boolean;
}

function VehicleList({ listSource, quickMenuRef, isDataFetched, hasVehicles }: IVehicleListProps) {
  const [toastShow, setToastShow] = useState<boolean>(false);
  const [isTooltipSelectedId, setTooltipSelectedId] = useState(-1); // tooltip 하나만 활성화
  const currentDealerState = useRecoilValue(currentDealerSelector);
  const setGlobalAlertStore = useSetRecoilState(globalAlertPropsState);
  const [compareStore, setCompareStore] = useRecoilState(compareSelector);
  const { cartItems = [] } = compareStore;
  const { t } = useTranslation();

  const addCompare = (addItem: Main.IVariant) => {
    const { productNo, baseModelId } = addItem;
    const added = cartItems.some((d: ComparePage.ICartItems) => d?.productNo === productNo);
    const filteredCartItems = cartItems.filter((d: ComparePage.ICartItems) => d?.productNo !== productNo);

    if (cartItems.filter((d: ComparePage.ICartItems) => d)?.length >= compareThreshold && !added) {
      setGlobalAlertStore({
        icon: 'ic_notice',
        message: t('Alert.ComparisonItemOver'),
        handlerOk: () => setGlobalAlertStore(null),
      });

      return;
    }

    !added && setToastShow(true);

    setCompareStore({
      cartItems: added
        ? filteredCartItems
        : [...cartItems, { productNo, baseModelId, dealerId: currentDealerState?.id }],
    });
  };

  const handleClickTestDrive = (data: Main.IVariant) => {
    quickMenuRef?.current?.openQuickMenuModal('testDrive', {
      quickMenu: {
        dealerId: currentDealerState?.id,
        model: data?.baseModelId,
      },
      // region: {
      //   province: currentDealerState?.province,
      //   district: currentDealerState?.district,
      //   subDistrict: currentDealerState?.subDistrict,
      // },
    });
  };

  // 리스트 새로 조회하면 툴팁 선택 초기화
  useEffect(() => {
    setTooltipSelectedId(-1);
  }, [listSource]);

  return (
    <div className="vehicle-area" css={vehicleListStyle}>
      {isDataFetched &&
        (hasVehicles ? (
          <ul className="vehicle-list-ul">
            {listSource?.map((data, elIdx) => (
              <VehicleListItem
                key={elIdx}
                data={data}
                handleClickTestDrive={handleClickTestDrive}
                addCompare={addCompare}
                isTooltipSelected={isTooltipSelectedId === elIdx}
                setTooltipSelected={() => setTooltipSelectedId(elIdx)}
              />
            ))}
          </ul>
        ) : (
          <EmptyVehicleList />
        ))}
      <ToastModal visible={toastShow} icon={true} button={true} closeModal={() => setToastShow(false)}>
        {t('SelectAModel.AddedToCompare')}
      </ToastModal>
    </div>
  );
}

export default VehicleList;
