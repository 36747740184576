import { TBDPrice, TBDBalancePrice } from '@/constants/common';
import React, { memo } from 'react';
import { useTranslation } from 'next-i18next';

interface Props {
  price: number;
  prefix?: string;
  postfix?: string;
  options?: Intl.NumberFormatOptions;
}

function IntlPrice({ price, prefix = '', postfix = '', options }: Props) {
  const priceFormat = price ? new Intl.NumberFormat('th', options).format(price) : 0;
  const { t } = useTranslation('common');

  return (
    <>
      {price !== TBDPrice && price !== TBDBalancePrice
        ? `${price > 0 ? prefix : ''} ${priceFormat} ${postfix}`
        : /**
           * 아이오닉 가격 미정 대응(11/27)
           * 51111 Baht => TBD로 변경
           * 1111 Baht => TBD로 변경
           */
          t('TBD')}
    </>
  );
}
export default memo(IntlPrice);
