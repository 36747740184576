import { UseQueryResult, useQueries, useQuery } from '@tanstack/react-query';
import { getContentImages } from './api';
import { chunk } from 'lodash-es';

/**
 * image 조회 api
 * 조회 가능한 최대 key 개수 10개
 */
export const useGetContentImages = (params: Contents.IContentImagesReq) => {
  params.imageKeys = [...new Set(params.imageKeys)];

  return useQuery([params], () => getContentImages(params), {
    keepPreviousData: true,
    enabled: !!params?.contentType && !!params?.imageType && !!params?.imageKeys?.[0] && params.imageKeys.length > 0,
  });
};

const MAX_REQUEST_NUM = 20;

export const useGetContentImagesPagination = (
  params: Contents.IContentImagesReq,
  pageNum: number = MAX_REQUEST_NUM,
) => {
  const resultQueries: UseQueryResult<Contents.IContentImagesRes>[] = useQueries({
    queries: chunk(params?.imageKeys?.map(String), pageNum).map(imageKeys => {
      const imageKeySet = [...new Set(imageKeys)];
      const currentParams = { ...params, imageKeys: imageKeySet };

      return {
        queryKey: [currentParams],
        queryFn: () => getContentImages(currentParams),
        enabled: !!params?.contentType && !!params?.imageType && !!imageKeySet[0] && imageKeySet.length > 0,
      };
    }),
  });

  return {
    data: { imageType: params?.imageType, images: resultQueries?.flatMap(({ data }) => data?.images) },
  };
};
