import { axiosInstance as axios } from '@/query/axios';

export const getBanner = async (params: Banner.IBannerReq) => {
  try {
    const response = await axios.get(`/product_api/banner/promotions`, {
      params: params,
    });

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};
