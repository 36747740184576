import React from 'react';
import classNames from 'classnames';
import { badgeGroupStyle } from './style';

const BadgeGroup = ({ className, children, ...otherProps }: IBadgeProps) => {
  return (
    <div css={badgeGroupStyle} className={classNames(className, 'badge-box')} {...otherProps}>
      {children}
    </div>
  );
};

export default BadgeGroup;
