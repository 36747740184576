import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const style = css`
  &.filter-content-container {
    height: 3.2rem;
    transition: all 0.3s;
    &.active {
      height: 22rem;
      .dim {
        visibility: hidden;
      }
      .filter-content-area {
        visibility: visible;
        height: 22rem;
      }
    }
    .filter-content-area {
      height: 3.2rem;
      visibility: hidden;
      transition: all 0.3s;
      overflow: hidden;
      flex: 1;
    }
    .tag-area {
      padding-top: 3.2rem;
    }
  }

  .filter-content-middle {
    display: flex;
    position: relative;
    padding: 3.2rem 0;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: ${colors.$color_d9d};
    }
  }
  .filter-content-box {
    ${flex('column', 'flex-start', 'flex-start')};
    flex: 1;

    &.range-slider {
      // flex: 3;
    }
    position: relative;
    &:not(:first-of-type) {
      padding-left: 3.2rem;
      border-left: 1px solid ${colors.$color_d9d};
    }
    &:last-of-type {
      padding: 0 3.2rem;
    }
    .title-box {
      ${font('1.6rem', 500, colors.$color_000, '2rem')};
    }
    .field-label {
      ${font('1.6rem', 500, colors.$color_000, '2rem')};
    }
    .content-box {
      margin-top: 3.2rem;

      .field-label {
        display: none;
      }

      .haefe-checkboxGroup {
        display: flex;
        flex-direction: column;
        width: 100%;

        .haefe-checkbox {
          margin: 0;
          .haefe-checkbox-label {
            margin-left: 0;
          }

          &:not(:first-of-type) {
            margin: 0px;
            margin-top: 1.6rem;
          }
        }

        .haefe-checkbox + .haefe-checkbox {
          margin-left: 0;
        }
      }
    }
  }

  .valueBox-range-top {
    ${flex('row', 'space-between', 'center')};
    width: 100%;
    margin-top: 3.2rem;

    .value-range {
      ${flex('row', 'flex-end', 'center')};
      overflow: hidden;
      width: 100%;
      height: 4rem;
      padding: 0 1.6rem;
      border-radius: 4px;
      border: 1px solid ${colors.$color_ddd};
      background-color: ${colors.$color_fff};
      ${font('1.6rem', 500, colors.$secondary1, '2.2rem')};
      text-align: right;
    }

    .left,
    .right {
      min-width: 21.2rem;
      height: 4.8rem;
      width: 100%;
      ${font('1.5rem', 400, colors.$color_000, '4.8rem')};
    }
  }
  .print-left,
  .print-right {
    min-width: 21.2rem;
    height: 4.8rem;
  }
  .separator {
    margin: 0 0.8rem;
  }

  .field-form {
    width: 100%;
    .haefe-range-wrapper {
      width: 100%;
      .haefe-range-left-handle,
      .haefe-range-right-handle {
        width: 22px;
        height: 22px;
        border: 5px solid #ffffff;

        &:active,
        &:hover {
          transform: scale(1) translateX(-50%);
        }
      }
    }
  }

  @media ${layout.$tablet} {
    &.filter-content-container {
      &.active {
        height: 43.3rem;
        .filter-content-area {
          height: 43.3rem;
        }
      }
      .filter-content-area {
        height: 3.2rem;
      }
    }
    .filter-content-middle {
      flex-wrap: wrap;
    }
    .filter-content-box {
      flex: 1;
      min-width: 22rem;
      padding: 0 3.2rem;
      margin-bottom: 3.2rem;

      &:last-of-type {
        min-width: unset;
        margin: 0;
        padding: 3.2rem 3.2rem 0;
        border-left: none;
        border-top: 1px solid ${colors.$color_d9d};
      }
      .content-box {
        width: 100%;
      }
    }
  }

  @media ${layout.$mobile} {
    &.filter-content-container {
      height: auto;
      transition: none;
      .tag-area {
        padding: 2.4rem 2rem 0;
      }
      .tag-lists {
        flex-wrap: wrap;
      }
      &.active {
        height: auto;
        .dim {
          visibility: visible;
          z-index: 99;
        }

        .filter-content-area {
          bottom: 0;
          opacity: 1;
          height: auto;
        }
      }
    }
    .filter-content-area {
      ${flex('column', 'flex-start', 'flext-start')};
      overflow: scroll;
      display: block !important;
      opacity: 0;
      max-height: -webkit-fill-available;
      padding-bottom: env(safe-area-inset-bottom);
      z-index: 9999;
      position: fixed;
      bottom: -100vh;
      width: 100%;
      margin: 0;
      border-radius: 1.2rem 1.2rem 0 0;
      background-color: ${colors.$color_fff};
      transition: all 0.3s;
    }
    .filter-content-top {
      .top-box {
        ${flex('row', 'space-between', 'center')};
        padding: 2rem;
        .filter-title-box {
          ${font('1.8rem', 500, colors.$color_000, '2.4rem')};
        }
      }
    }
    .filter-content-middle {
      ${flex('column', 'flex-start', 'flex-start')};
      flex-wrap: unset;
      overflow-y: auto;
      padding: 0 2rem;
      &::before {
        display: none;
      }
    }
    .filter-content-box {
      width: 100%;
      margin: 0;
      padding: 1.6rem 0 2.4rem;
      flex: 1;

      &:not(:first-of-type) {
        padding: 1.6rem 0 2.4rem;
        border-top: 1px dashed ${colors.$color_999};
        border-left: none;
      }
      .title-box {
        ${font('1.6rem', 400, colors.$color_000, '2.4rem')};
        margin-bottom: 1.2rem;
      }

      .content-box {
        flex-wrap: wrap;
        margin: -0.4rem -0.2rem;

        .haefe-checkboxGroup {
          ${flex('row', 'flex-start', 'flex-start')};

          .haefe-checkbox {
            margin: 0.4rem 0.2rem;
            &:not(:first-of-type) {
              margin: 0.4rem 0.2rem;
            }

            .haefe-checkbox-label {
              width: 100%;
              margin: 0;
              border-radius: 1.6rem;
              border: 1px solid ${colors.$color_999};
              background-color: ${colors.$color_fff};
              transition: all 0.3s;
              .haefe-checkbox-content {
                ${flex('row', 'center', 'center')};
                width: auto;
                height: 3.2rem;
                margin: 0;
                padding: 0 1.2rem;
                ${font('1.3rem', 400, colors.$color_999, '2rem')};
              }

              &.haefe-checkbox-checked {
                color: ${colors.$color_fff};
                border-color: ${colors.$secondary1};
                background-color: ${colors.$secondary1};
              }
            }

            input[type='checkbox'] {
              &:checked {
                + .haefe-checkbox-itag {
                  + .haefe-checkbox-content {
                    color: ${colors.$color_fff};
                    border-color: ${colors.$secondary1};
                    background-color: ${colors.$secondary1};
                  }
                }
              }
            }
            .haefe-checkbox-itag {
              display: none;
            }
          }
        }
      }

      .valueBox-range-top {
        margin-top: 0;
        margin-bottom: 2.6rem;

        .left,
        .right {
          min-width: unset;
        }

        .value-range {
          ${font('1.6rem', 500, colors.$secondary1, '4.8rem')};
        }
      }

      .haefe-range-wrapper {
        padding: 0px 15px;
      }
    }
    .filter-content-bottom {
      padding: 2rem;
      .button-box {
        ${flex('row', 'space-between', 'center')};
        margin: 0 -0.4rem;
        button {
          width: 100%;
          margin: 0 0.4rem;
        }
      }
    }
  }
`;

export default style;
