import { pickNotEmptyValues } from '@utils/convert';
import { omit } from 'lodash-es';
import { PRICE_RANGE_MAX, PRICE_RANGE_MIN } from 'pages/index/constants';
import { atom, selector } from 'recoil';

export const isModelFilterOpenState = atom<boolean>({
  key: 'stores.clickToBuy.modelFilter.atom.isModelFilterOpen',
  default: false,
});

export const isModelFilterOpenSelector = selector<boolean>({
  key: 'stores.clickToBuy.modelFilter.selector.isModelFilterOpen',

  get: ({ get }) => {
    return get(isModelFilterOpenState);
  },

  set: ({ set }, value) => {
    set(isModelFilterOpenState, value);
  },
});

export const isDefaultfilterSelector = selector({
  key: 'stores.clickToBuy.modelFilter.selector.isDefaultfilter',

  get: ({ get }) => {
    const filterState = get(selectedModelFilterState);

    const isDefaultPrice =
      (filterState?.maxPriceRange ?? PRICE_RANGE_MAX) === PRICE_RANGE_MAX &&
      (filterState?.minPriceRange ?? PRICE_RANGE_MIN) === PRICE_RANGE_MIN;

    const isDefaultFilterState = !Object.values(omit(filterState, ['maxPriceRange', 'minPriceRange']) ?? {})?.some(
      filterList => filterList?.length !== 0,
    );

    return isDefaultFilterState && isDefaultPrice;
  },
});

export const selectedModelFilterState = atom<Main.TModelFilterState>({
  key: 'stores.clickToBuy.modelFilter.atom.selectedModelFilter',
  default: null,
});

export const selectedModelFilterSelector = selector<any>({
  key: 'stores.clickToBuy.modelFilter.selector.selectedModelFilter',

  get: ({ get }) => {
    return get(selectedModelFilterState);
  },

  set: ({ set }, value: Partial<Main.TModelFilterState>) => {
    const newFilterData = pickNotEmptyValues({ ...value });

    set(selectedModelFilterState, Object.keys(newFilterData).length === 0 ? null : newFilterData);
  },
});

export const vehicleListState = atom<Main.IModel[]>({
  key: 'stores.clickToBuy.modelFilter.atom.vehicleList',
  default: null,
});

export const vehicleListSelector = selector<Main.IModel[]>({
  key: 'stores.clickToBuy.modelFilter.selector.vehicleList',

  get: ({ get }) => {
    return get(vehicleListState);
  },

  set: ({ set }, value) => {
    set(vehicleListState, value);
  },
});
