import Image from 'next/image';
import { useTranslation } from 'next-i18next';

function EmptyVehicleList() {
  const { t } = useTranslation();

  return (
    <div className="No-vehicleList">
      <div className="vehicle-box">
        <div className="vehicle-box-middle">
          <div className="vehicle-box-middle-top">
            <div className="text-box">
              <div className="title-box">
                <span className="desc"></span>
                <div className="title">
                  <span>{t('Label.NoVehicleList')}</span>
                </div>
              </div>
              <div className="price-box">
                <span className="text"></span>
                <span className="price"></span>
                <span className="unit"></span>
              </div>
            </div>
          </div>
          <div className="vehicle-box-middle-middle">
            <div className="image-box">
              <Image width={150} height={21} src={'/assets/images/thum_vehicle.png'} alt="" />
            </div>
          </div>
          <div className="vehicle-box-middle-bottom"></div>
        </div>
      </div>
    </div>
  );
}

export default EmptyVehicleList;
