import { axiosInstance as axios } from '@/query/axios';

export const getCategories = async (): Promise<Product.ICategoryRes[]> => {
  try {
    const response = await axios.get(`/product_api/category/displays`);

    return response?.data || null;
  } catch (err: any) {
    throw err;
  }
};
