import classNames from 'classnames';
import React from 'react';
import Badge from '@/components/units/badge';
import tagStyle from './style';
import TagGroup from './tagGroup';
import { TTagOption } from './type';

type TTagProps = {
  [otherProps: string]: any;
} & TTagOption;

function Tag({
  content,
  value,
  isActive = true,
  type = 'normal',
  disabled = false,
  onClickDelete,
  onClick,
  hideCloseIcon = false,
  ...otherProps
}: TTagProps) {
  return (
    <li className="tag-list" css={tagStyle} {...otherProps}>
      <Badge>
        <button
          type="button"
          onClick={onClick}
          className={classNames('tag', { active: !disabled && isActive }, { 'tag-del-type': type === 'del' })}
          disabled={disabled}
        >
          <span>{content}</span>
          {!disabled && (
            <span
              onClick={() => onClickDelete(value)}
              className={classNames('ic', { 'ic-tag-close': type === 'del' && !hideCloseIcon })}
            />
          )}
        </button>
      </Badge>
    </li>
  );
}

Tag.Group = TagGroup;

export default Tag;
