import React, { useEffect, useState } from 'react';
import TooltipStyle from './style';

type TAlign = 'top' | 'bottom' | string;

const Tooltip = ({
  align,
  message = `
This price is valid for first vehicle ownership only.`,
  isSelected,
  onSelected,
}: {
  align: TAlign;
  message?: string;
  isSelected?: boolean;
  onSelected?: () => void;
}) => {
  const [tooltipShow, setTooltipShow] = useState<boolean>(false);

  useEffect(() => {
    setTooltipShow(isSelected);
  }, [isSelected]);

  // message가 여러 개인 경우
  const messages = message.split('\n').map((paragraph, index) => <p key={index}>{paragraph}</p>);

  return (
    <div className="tooltip-box" css={TooltipStyle}>
      <button
        type="button"
        className="tooltip-icon-box"
        onClick={e => {
          e.stopPropagation();
          setTooltipShow(!tooltipShow);
          onSelected && onSelected();
        }}
      >
        <i className="ic ic-tooltip-info"></i>
      </button>

      {/* 
      Class   : active
      Comment : tooltip 활성화 시

      Class   : align-top , align-bottom
      Comment : align-top: tooltip 상단 정렬 , align-bottom: tooltip 하단 정렬 */}

      <div className={`tooltip align-${align} ${tooltipShow ? 'active' : ''}`}>
        <i className="ic ic-tooltip-tail"></i>
        <button
          type="button"
          className="btn-tooltip-close"
          onClick={e => {
            e.stopPropagation();
            setTooltipShow(false);
          }}
        >
          <i className="ic ic-tooltip-close"></i>
        </button>
        <div className="tooltip-text-box">{messages}</div>
      </div>
    </div>
  );
};

export default Tooltip;
