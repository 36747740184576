import { axiosInstance as axios } from '@/query/axios';

/**
 * 이미지 조회
 *
 * 다중 조회 기능 - imageKeys를 리스트로 받아 ,로 구분하여 여러 이미지 key 조회
 * ex). '1,2,3'
 *
 * 실패 시 images에 빈 리스트 리턴
 */
export const getContentImages = async (params: Contents.IContentImagesReq): Promise<Contents.IContentImagesRes> => {
  try {
    const { contentType, imageKeys, imageType } = params;

    const response = await axios.get(`/content_api/${contentType}/images`, {
      params: { imageType, imageKeys: imageKeys.join(',') },
    });

    return response.data;
  } catch (err: any) {
    // api 호출 실패 시에도 빈 리스트 리턴되도록 설정
    return {
      imageType: params.imageType,
      images: params.imageKeys.map(imageKey => {
        return { imageKey, imageValues: [] };
      }),
    };
  }
};
