import { css } from '@emotion/react';
import { colors, font, layout } from 'styles/mixin';

const TooltipStyle = css`
  position: relative;
  .tooltip-icon-box {
    width: 2rem;
    height: 2rem;
    .ic {
      width: 100% !important;
      height: 100% !important;
      background-size: 100% !important;
    }
  }
  .tooltip {
    z-index: 22;
    display: none;
    position: absolute;
    width: 32.8rem;
    padding: 2rem 2.8rem 2rem 4rem;
    border: 1px solid ${colors.$secondary1};
    border-radius: 0.1rem;
    background-color: ${colors.$color_fff};
    &.active {
      display: block;
    }

    &.align-top {
      bottom: 2.8rem;
      .ic-tooltip-tail {
        bottom: -0.7rem;
        left: 3.9rem;
        transform: rotate(180deg);
      }
    }

    &.align-bottom {
      top: 2.8rem;
      .ic-tooltip-tail {
        top: -0.7rem;
        left: 3.9rem;
      }
    }

    .ic-tooltip-tail {
      position: absolute;
    }

    .btn-tooltip-close {
      position: absolute;
      top: 0.8rem;
      right: 0.8rem;
    }

    .tooltip-text-box {
      p {
        position: relative;
        ${font('1.5rem', 400, colors.$color_000, '2.2rem')};
        word-break: break-word;
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 0.7rem;
          left: -1.2rem;
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 100%;
          background-color: ${colors.$color_333};
        }
      }
    }
  }

  @media ${layout.$mobile} {
    .tooltip {
      z-index: 9999;
      width: calc(100vw - 4rem);
      max-width: 36rem;
      padding: 2rem 3.2rem 2rem 2.8rem;

      .tooltip-text-box {
        p {
          font-size: 1.2rem;
          line-height: 1.8rem;
        }
      }
    }
  }
`;

export default TooltipStyle;
