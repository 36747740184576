import React, { PropsWithChildren } from 'react';
import BannerStyle from './style';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Pagination, Autoplay, EffectFade } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import Link from 'next/link';
import { useBanner } from '@/query/banner/query';
import { IBannerProps, IBannerData } from './type';
import Image from 'next/image';
import useDevice from '@/hooks/useDevice';
import { gaAttributeBanner } from '@/utils/gaCommon';
import { gaAttributeName } from '@/constants/common';

function Banner({ displayArea, displayPagePolicy }: PropsWithChildren<IBannerProps>) {
  const { data } = useBanner({ displayPagePolicy });
  const device = useDevice();

  // TODO: 추후 /common store에 device 관련 state 생성 후 수정
  const DEVICE_TYPE: 'PC' | 'MOBILE' = device === 'MOBILE' ? device : 'PC';

  if (Array.isArray(data) && !data?.length) return <></>;

  return (
    <>
      <Swiper
        effect="fade"
        autoHeight={true}
        autoplay={{ delay: 3000, disableOnInteraction: false, pauseOnMouseEnter: true }}
        slidesPerView={'auto'}
        pagination={{ el: '.banner-swiper-pagination', clickable: true }}
        modules={[Pagination, Autoplay, EffectFade]}
        className={`banner-container ${displayArea === 'BOTTOM' ? 'bottom' : ''}`}
        css={BannerStyle}
      >
        {data
          ?.filter((d: IBannerData) => d.displayArea === displayArea)
          .map((d: IBannerData, i: number) => {
            const bannerData = d?.bannerLinkInfo;

            return (
              <SwiperSlide key={i}>
                {DEVICE_TYPE === 'MOBILE' ? (
                  <Link
                    className="banner-link"
                    href={bannerData?.linkUrlMobile ? `https://${bannerData?.linkUrlMobile}` : '/'}
                    target={`${bannerData?.newWindowMobileYn === 'Y' ? '_blank' : '_self'}`}
                  >
                    <Image
                      width={720}
                      height={260}
                      src={process.env.NEXT_PUBLIC_CONTENTS_HOST + d?.bannerLinkInfo?.imageKeyMobile}
                      alt="banner1"
                      {...gaAttributeBanner({
                        category: gaAttributeName.CTB.CATEGORY,
                        bannerTitle: d?.title,
                        bannerNo: d?.id,
                      })}
                    />
                  </Link>
                ) : (
                  <Link
                    className="banner-link"
                    href={bannerData?.linkUrlPc ? `https://${bannerData?.linkUrlPc}` : '/'}
                    target={`${bannerData?.newWindowPcYn === 'Y' ? '_blank' : '_self'}`}
                  >
                    <Image
                      width={1120}
                      height={130}
                      src={process.env.NEXT_PUBLIC_CONTENTS_HOST + d?.bannerLinkInfo?.imageKeyPc}
                      alt="banner1"
                      {...gaAttributeBanner({
                        category: gaAttributeName.CTB.CATEGORY,
                        bannerTitle: d?.title,
                        bannerNo: d?.id,
                      })}
                    />
                  </Link>
                )}
              </SwiperSlide>
            );
          })}

        <div className="swiper-pagination-container">
          <div className="swiper-pagination banner-swiper-pagination"></div>
        </div>
      </Swiper>
    </>
  );
}

export default Banner;
