import { isDefaultfilterSelector, isModelFilterOpenSelector, selectedModelFilterSelector } from '@/stores/ctb';
import classNames from 'classnames';
import React from 'react';
import { useTranslation } from 'next-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import filterButtonStyle from './style';

function FilterButton({ ...otherProps }: Global.IAnyObject) {
  const [isModelFilterOpen, setIsModelFilterOpen] = useRecoilState(isModelFilterOpenSelector);
  const isDefaultFilterState = useRecoilValue<any>(isDefaultfilterSelector);
  const selectedModelFilterState = useRecoilValue(selectedModelFilterSelector);
  const { t } = useTranslation();

  return (
    <button
      onClick={() => setIsModelFilterOpen(!isModelFilterOpen)}
      type="button"
      className={classNames('btn-filter', { active: isModelFilterOpen })}
      css={filterButtonStyle}
      {...otherProps}
    >
      <i
        className={classNames(
          'ic',
          { 'ic-filter': isDefaultFilterState || !selectedModelFilterState },
          { 'ic-filter-s': !isDefaultFilterState && !!selectedModelFilterState },
        )}
      ></i>
      <span className="use-pc">{t('SelectAModel.Filter')}</span>
    </button>
  );
}

export default FilterButton;
