import { css } from '@emotion/react';
import { colors, flex, font, layout } from 'styles/mixin';

const ctbMainStyle = css`
  width: 112rem;
  margin: 0 auto;
  padding-bottom: 12rem;
  .select-model-container {
    margin-top: 6rem;
    .select-model-top {
      .title-box {
        ${font('2.6rem', 500, colors.$color_000, '3.2rem')};
      }
    }
    .select-model-middle {
      margin-top: 6rem;
      .select-model-middle-top {
        ${flex('row', 'space-between', 'flex-start')};
        border-bottom: 1px solid ${colors.$color_ddd};
      }
      .select-model-middle-top-left {
        overflow: hidden;

        .haefe-tab-item + .haefe-tab-item {
          margin-left: 4.8rem;
        }
      }
    }
    .select-model-bottom {
      padding-top: 3.2rem;

      .select-model-bottom-top {
        ${flex('row', 'space-between', 'center')};
        margin: 6rem 0 2.4rem;
        .total-box {
          ${font('1.6rem', 400, colors.$color_000, '2rem')};
          .total-num {
            margin: 0 0.4rem;
            font-weight: 500;
            color: ${colors.$secondary1};
          }
        }
        .btn-compare {
          ${flex('row', 'center', 'center')};
          .compare-count-box {
            position: relative;
            .count {
              ${flex('row', 'center', 'center')};
              position: absolute;
              top: 0.1rem;
              right: -0.2rem;
              width: 1.6rem;
              height: 1.6rem;
              border-radius: 50%;
              background-color: ${colors.$secondary1};
              ${font('0.8rem', 500, colors.$color_fff, '0.9rem')};
            }
          }
          .text {
            margin-left: 0.8rem;
            ${font('1.6rem', 400, colors.$color_000, '2rem')};
          }
        }
      }
    }
    .vehicle-area {
      // ${flex('row', 'space-between', 'flex-start')};
      // flex-wrap: wrap;
      margin: -1.6rem;
    }
  }

  .ctb-models {
    width: 112rem;
    margin: 0 auto;
    padding-bottom: 12rem;
  }

  @media ${layout.$tablet} {
    width: 100%;
    padding: 0 0 12rem;

    .ctb-models {
      width: 100%;
      padding: 0 0 12rem;
    }

    .select-model-container {
      margin-top: 6.4rem;
      padding: 0 4.4rem 0;
    }

    .modal-change-dealer {
      .dealer-container {
        .dealer-bottom {
          margin: 1.8rem -0.8rem 0;
          height: 46.1rem;
          .dealer-bottom-left {
            width: 50%;
            margin: 0 0.8rem;
            padding: 0;
          }
          .dealer-bottom-right {
            width: 50%;
            height: 100%;
            margin: 0 0.8rem;
          }
        }
        .map-container {
          width: 100%;
          height: 100%;
        }
      }
      .field-box {
        flex-wrap: wrap;
      }

      .dealer-block {
        .dealer-block-top {
          display: block;
          text-align: left;
          .dealer-block-top-right {
            margin-top: 0.8rem;
          }
        }
      }
    }
  }

  @media ${layout.$mobile} {
    padding: 0;

    .select-model-container {
      margin-top: 1.5rem;
      padding: 0;
      .select-model-top {
        padding: 0 2rem;
        .title-box {
          ${font('1.8rem', 500, colors.$color_000, '2.4rem')};
        }
      }

      .select-model-area {
        position: relative;
      }
      .select-model-middle {
        margin: 3.5rem 0 2.4rem;
        .select-model-middle-top {
          justify-content: flex-start;
        }
      }
      .select-model-middle-top-left {
        padding: 0 2rem;
      }
      .select-model-middle-top-right {
        position: absolute;
        top: 0;
        right: 2rem;
      }

      .modal-change-dealer {
        .dealer-container {
          position: relative;
          margin-top: 2.4rem;
          padding-top: 55.55vw;
          .dealer-area {
            padding-top: 2.4rem;
          }
          .dealer-bottom {
            display: block;
            height: auto;
            margin: 2.4rem 0 0;
            .dealer-bottom-left {
              width: 100%;
              margin: 0;
            }
            .dealer-bottom-right {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 55.55vw;
              margin: 0;
            }
          }
        }

        .dealer-block {
          .dealer-block-top {
            .dealer-block-top-right {
              margin-top: 0;
            }
          }
        }
      }
      .ctb-models {
        padding: 0;
      }
      .select-model-bottom {
        padding-top: 0;
        ${flex('column-reverse', 'flex-start', 'flex-start')};
        background-color: ${colors.$color_f4f};
        > div {
          width: 100%;
        }
        .select-model-bottom-top {
          display: block;
          margin: 0.8rem 0 0;
          .select-model-bottom-top-left {
            display: none;
          }
          .select-model-bottom-top-right {
            width: 100%;
            padding: 2.4rem 0;
            background-color: ${colors.$color_fff};
          }
          .btn-compare {
            margin: 0 auto;
            .compare-count-box {
              display: none;
            }
            .text {
              margin: 0;
              ${font('1.3rem', 500, colors.$primary, '2rem')};
              text-decoration-line: underline;
            }
          }
        }
      }
      .vehicle-area {
        ${flex('column', 'flex-start', 'flex-start')};
        margin: 0;
      }
    }
  }
`;

export default ctbMainStyle;
