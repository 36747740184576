export const TEMP_FORM_DATA: { [key: string]: { value: string; label: any }[] } = {
  fuelTypes: [
    { value: 'Diesel', label: 'Diesel' },
    { value: 'Gasoline', label: 'Gasoline' },
    { value: 'Electric', label: 'Electric' },
  ],
  transmitAutoYns: [
    { value: 'Y', label: 'Auto' },
    { value: 'N', label: 'Manual' },
  ],
  tractions: [
    { value: '2WD', label: '2WD' },
    { value: '4WD', label: '4WD' },
    { value: 'AWD', label: 'AWD' },
  ],
};

export const PRICE_RANGE_MIN = 100000;
export const PRICE_RANGE_MAX = 3000000;
export const PRICE_RANGE_STEP = 10000;
export const ALL_CATEGORY_CODE = 0;
