import { css } from '@emotion/react';
import { colors, flex, font } from 'styles/mixin';

const badgeStyle = (color: string, fontColor: string) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 2.4rem;
  padding: 0 0.8rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  line-height: 1.8rem;

  background-color: ${color ?? '#002C5F'} !important;
  color: ${fontColor ?? '#fff'};

  &.outline {
    border: 1px solid ${color ?? '#002C5F'};
    background-color: transparent;
    color: ${color ?? '#fff'};
  }
`;

export const badgeGroupStyle = css`
  ${flex('row', 'flex-start', 'flex-start')};

  .badge {
    ${flex('row', 'center', 'center')};
    width: fit-content;
    height: 2.4rem;
    padding: 0 0.8rem;
    background-color: ${colors.$primary};
    ${font('1.2rem', 400, colors.$color_fff, '1.8rem')};
    &.secondary {
      background-color: ${colors.$secondary1};
    }
    &:not(:first-of-type) {
      margin-left: 0.2rem;
    }
  }
`;

export default badgeStyle;
