import Badge from '@/components/units/badge';
import BadgeGroup from '@/components/units/badge/badgeGroup';
import Button from '@/components/units/button';
import Tooltip from '@/components/units/tooltip';
import classNames from 'classnames';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import useMenuLists from '@/hooks/useMenuList';
import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { currentDealerSelector, selfSelectedDealerSelector } from '@/stores/common/user';
import { compareSelector } from '@/stores/compare';
import { gaAttributes } from '@/utils/gaCommon';
import { IONIQ, TBDPrice } from '@/constants/common';
import IntlPrice from '@/components/units/IntlPrice';

interface IVehicleListItem {
  data: Main.IVariant;
  handleClickTestDrive: (data: Main.IVariant) => void;
  addCompare: (addItem: Main.IVariant) => void;
  // tooltip을 하나만 활성화
  isTooltipSelected: boolean;
  setTooltipSelected: () => void;
}

function VehicleListItem({
  data,
  handleClickTestDrive,
  addCompare,
  isTooltipSelected,
  setTooltipSelected,
}: IVehicleListItem) {
  const router = useRouter();
  const { t } = useTranslation();
  const menuLists = useMenuLists();
  const currentDealerState = useRecoilValue(currentDealerSelector);
  const compareStore = useRecoilValue(compareSelector);
  const { cartItems } = compareStore;
  const isCompareTarget = cartItems?.some((d: ComparePage.ICartItems) => d?.productNo === data?.productNo);
  const isSelfSelectedDealer = useRecoilValue(selfSelectedDealerSelector);

  const handleClickVehicleCard = (data: Main.IVariant) => {
    const isVariant = data?.variantDisplayName;

    const variantSelectQueryKeys: Main.TVariantSelectQueryKey[] = [
      'baseModelId',
      'productNo',
      'baseVariantId',
      'productPrice',
    ];
    const modelBuildQueryKeys: Main.TModelBuildQueryKey[] = ['baseModelId', 'categoryType', 'categoryCode'];

    const targets: Main.TModelBuildQueryKey[] | Main.TVariantSelectQueryKey[] = isVariant
      ? variantSelectQueryKeys
      : modelBuildQueryKeys;

    const queries = {
      ...menuLists.ctb?.query,
      ..._.pick(data, targets),
      dealerId: isSelfSelectedDealer ? currentDealerState.id : data.dealerId,
    };

    router.push({
      pathname: menuLists.ctb.pathname || '/',
      query: queries,
    });
  };

  const handleClickDownLoadEBrochure = () => {
    // TODO en -> th 로 변경(언어 처리 변경) 런칭후 WWN 영문 지원시 언어처리 필요
    window.location.href = `${process.env.NEXT_PUBLIC_WWN_HOST}/${router.query.lang}/build-a-car/download-brochure`;
  };

  return (
    <li className="vehicle-box">
      <div className="vehicle-box-top">
        <div className="vehicle-box-top-left">
          <BadgeGroup>
            {data.categoryBadges?.map(
              (
                { badgeColorCodeBackground, badgeColorCodeFont, badgeDisplayName }: Product.ICategoryBadge,
                index: number,
              ) => (
                <Badge key={index} color={`#${badgeColorCodeBackground}`} fontColor={`#${badgeColorCodeFont}`}>
                  {badgeDisplayName}
                </Badge>
              ),
            )}
          </BadgeGroup>
        </div>
        <div className="vehicle-box-top-right">
          <button
            type="button"
            className="compare-box"
            onClick={() => addCompare(data)}
            {...gaAttributes({ action: 'ic-compare', label: data?.modelDisplayName })}
          >
            <i
              className={classNames('ic', 'ic-compare', {
                'ic-compare-s': isCompareTarget,
              })}
            ></i>
          </button>
        </div>
      </div>
      <div className="vehicle-box-middle" onClick={() => handleClickVehicleCard(data)}>
        <div className="vehicle-box-middle-top">
          <div className="text-box">
            <div className="title-box">
              {data?.variantDisplayName && <span className="desc">{data?.variantDisplayName}</span>}
              <div className="title">
                <span>{data?.modelDisplayName}</span>
              </div>
            </div>
            <div className="price-box">
              <span className="text">{t('Label.From')}</span>
              <span className="price">
                <IntlPrice price={data?.productPrice} />
              </span>
              <span className="unit">{t('Label.Baht')}</span>
              <Tooltip
                align={'bottom'}
                message={
                  data?.productPrice !== TBDPrice
                    ? t('ToolTip.OTRPriceText')
                    : // TBD 가격 미정인 경우 노출 tooltip
                      `${t('ToolTip.ThePriceWillBeAnnouncedSoon')}`
                }
                isSelected={isTooltipSelected}
                onSelected={() => {
                  setTooltipSelected();
                }}
              />
            </div>
            {data?.electricYn === 'Y' && (
              <div className="electric-info-box">
                <span className="text">{t('ToolTip.ElectricVarientPolicy')}</span>
              </div>
            )}
          </div>
        </div>
        <div className="vehicle-box-middle-middle">
          <div className="image-box">
            <Image width={150} height={21} src={data.imgUrl || '/assets/images/thum_vehicle.png'} alt="" />
          </div>
        </div>
        <div className="vehicle-box-middle-bottom">
          <Button
            buttonType=""
            className="btn h56"
            onClick={() => {
              handleClickVehicleCard(data);
            }}
            {...gaAttributes({
              action: t(data?.variantDisplayName ? 'Label.Build' : 'Label.Select'),
              label: data?.modelDisplayName,
            })}
          >
            {t(data?.variantDisplayName ? 'Label.Build' : 'Label.Select')}
          </Button>
          <Button
            buttonType=""
            className="btn h56"
            onClick={e => {
              e.stopPropagation();
              handleClickTestDrive(data);
            }}
            {...gaAttributes({ action: t('MyGarage.TestDrive'), label: data?.modelDisplayName })}
          >
            {t('MyGarage.TestDrive')}
          </Button>
        </div>
      </div>
      <div className="vehicle-box-bottom">
        <Button
          buttonType="text"
          height="auto"
          className="btn"
          onClick={handleClickDownLoadEBrochure}
          {...gaAttributes({ action: t('Label.DownloadE-Brochure'), label: data?.modelDisplayName })}
        >
          {t('Label.DownloadE-Brochure')}
        </Button>
      </div>
    </li>
  );
}

export default VehicleListItem;
