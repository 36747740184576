import { isProdOffConsole } from '@/config';
import { useEffectOnce } from 'usehooks-ts';

function useDevelopInfo(): void {
  useEffectOnce(() => {
    if (process.env.NEXT_PUBLIC_SERVICE_TYPE !== 'Prod') {
      console.group('Buyonline Configuration');
      isProdOffConsole(`Last Build ENV: ${process.env.NODE_ENV}`);
      isProdOffConsole(`Last Build Type: ${process.env.NEXT_PUBLIC_SERVICE_TYPE}`);
      isProdOffConsole(`Last Build Time: ${process.env.lastBuildTime}`);
      console.groupEnd();
    }
  });
}

export default useDevelopInfo;
