import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import Button from '@components/units/button';
import { Checkbox, Form, RangeSlider } from '@components/haeLibraryRefactored';
import style from './style';
import Tag from '@components/units/tag';
import { priceWithComma } from '@/utils/convert';
import {
  isDefaultfilterSelector,
  isModelFilterOpenSelector,
  selectedModelFilterSelector,
  vehicleListSelector,
} from '@/stores/ctb';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { TTagOption } from '@components/units/tag/type';
import { currentDealerSelector, selfSelectedDealerSelector } from '@/stores/common/user';
import { useTranslation } from 'next-i18next';
import { PRICE_RANGE_MAX, PRICE_RANGE_MIN, PRICE_RANGE_STEP, TEMP_FORM_DATA } from 'pages/index/constants';
import useDevice from '@/hooks/useDevice';
import { useFilterVariants, useModels, useVariants } from '@/query/product/query';
import Dim from '@/components/modules/dim';
import { globalSpinnerState } from '@/stores/common';
import useForm from '@/components/haeLibraryRefactored/Form/hooks/useForm';
import { AnyObj } from '@/components/haeLibraryRefactored/type';
import { omit } from 'lodash-es';

function ModelFilter({ category }: IModelFilterProps) {
  const initialSelectedFilter: { price: [number, number] } = {
    price: [PRICE_RANGE_MIN, PRICE_RANGE_MAX],
  };

  const { t } = useTranslation();
  const [device] = useDevice();
  const form = useForm();

  const [isModelFilterOpen, setIsModelFilterOpen] = useRecoilState<boolean>(isModelFilterOpenSelector);
  const isDefaultFilterState = useRecoilValue<any>(isDefaultfilterSelector);
  const [selectedModelFilterState, setSelectedModelFilterState] =
    useRecoilState<Partial<Main.TModelFilterState>>(selectedModelFilterSelector);
  const setVehicleList = useSetRecoilState(vehicleListSelector);
  const currentDealerState = useRecoilValue(currentDealerSelector);
  const [loading, setLoading] = useRecoilState(globalSpinnerState);
  const [selectedFilter, setSelectedFilter] = useState<TFilterData>(initialSelectedFilter);
  const [count, setCount] = useState(null);

  const [rangeValue, setRangeValue] = useState<[number, number]>([PRICE_RANGE_MIN, PRICE_RANGE_MAX]);

  const isDefaultFilter = useMemo(() => {
    const isDefaultPrice =
      (selectedFilter?.price?.[1] ?? PRICE_RANGE_MAX) === PRICE_RANGE_MAX &&
      (selectedFilter?.price?.[0] ?? PRICE_RANGE_MIN) === PRICE_RANGE_MIN;

    const isDefaultFilterList = !Object.values(omit(selectedFilter, ['price']) ?? {})?.some(
      filterList => filterList?.length !== 0,
    );

    const isMobileDefault = isDefaultPrice && isDefaultFilterList;

    return device === 'M' ? isMobileDefault : isDefaultFilterState;
  }, [selectedFilter, isDefaultFilterState]);

  const convetFilterStateToStore = (input: TFilterData) => {
    const { price, ...otherFilters } = input;

    const filters: Main.TVariantFilterDto = { ...otherFilters };

    if (price?.[1]) filters.maxPriceRange = price?.[1];
    if (price?.[0] !== undefined) filters.minPriceRange = price?.[0];

    return filters;
  };

  const convetFilterStoreToState = (input: Main.TModelFilterState) => {
    const { maxPriceRange, minPriceRange, ...otherFilters } = input;

    const filters: TFilterData = { ...otherFilters, price: [minPriceRange, maxPriceRange] };

    return filters;
  };

  const currentFilter = device !== 'M' ? selectedModelFilterState : convetFilterStateToStore(selectedFilter);

  const closeFilter = () => {
    const prevSelectedFilter = convetFilterStoreToState(selectedModelFilterState);

    setSelectedFilter(prevSelectedFilter);
    setIsModelFilterOpen(false);
    setRangeValue(prevSelectedFilter?.price);
    form.setFieldsValue(prevSelectedFilter);
  };

  const resetFilter = () => {
    setSelectedFilter(initialSelectedFilter);
    setSelectedModelFilterState({ maxPriceRange: PRICE_RANGE_MAX, minPriceRange: PRICE_RANGE_MIN });
    setRangeValue([PRICE_RANGE_MIN, PRICE_RANGE_MAX]);
    form.resetFields();
  };

  const handleClickReset = () => {
    setSelectedFilter(initialSelectedFilter);
    setRangeValue([PRICE_RANGE_MIN, PRICE_RANGE_MAX]);
    form.setFieldsValue({ price: [PRICE_RANGE_MIN, PRICE_RANGE_MAX] });
  };

  const resetTagOption: TTagOption = {
    value: {},
    content: 'Reset',
    type: 'del',
    onClick: () => resetFilter(),
    hideCloseIcon: true,
    isActive: false,
  };

  const tagOptions: TTagOption[] = useMemo(
    () =>
      Object.entries(omit(selectedModelFilterState, ['maxPriceRange', 'minPriceRange'])).flatMap(
        (entry: [string, string[]]) => {
          const [type, value] = entry;

          return value.map((subItem: string) => {
            const target = TEMP_FORM_DATA[type]?.find((item: any) => item.value === subItem);

            return { value: { id: subItem, type: type }, content: target.label, type: 'del' };
          });
        },
      ),
    [selectedModelFilterState],
  );

  const onClickDelete = (value: { id: string; type: TFilterKey }) => {
    const filteredSelectedList = selectedFilter[value.type]?.filter((item: string) => item !== value.id);

    form.setFieldsValue({ ...selectedFilter, [value.type]: filteredSelectedList });

    const newSelectedFilter = { ...selectedFilter, [value?.type]: filteredSelectedList };

    setSelectedFilter(newSelectedFilter);
    setSelectedModelFilterState(convetFilterStateToStore(newSelectedFilter));
  };

  const handleChangeFormValue = (value: AnyObj) => {
    const newSelectedFilter = {
      ...selectedFilter,
      ...value,
    };

    setRangeValue(newSelectedFilter?.price);
    setSelectedFilter(newSelectedFilter);

    if (device === 'T' || device === 'D') {
      setSelectedModelFilterState(convetFilterStateToStore(newSelectedFilter));
    }
  };
  const isSelfSelectedDealer = useRecoilValue(selfSelectedDealerSelector);

  const { data: dataModels, isFetching: isModelFetching } = useModels({
    dealerId: !isDefaultFilter ? null : isSelfSelectedDealer ? currentDealerState?.id : null,
    categoryType: category?.categoryType ?? null,
    categoryCode: category?.categoryCode ?? null,
  });

  const { data: dataVariants, isFetching: isVariantFetching } = useVariants({
    dealerId: !isDefaultFilter ? null : isSelfSelectedDealer ? currentDealerState?.id : null,
    categoryType: category?.categoryType ?? null,
    categoryCode: category?.categoryCode ?? null,
  });

  const { data: dataFilterVariants, isFetching: isFilterVariantFetching } = useFilterVariants({
    dealerId: isSelfSelectedDealer ? currentDealerState?.id : null,
    variantFilterDto: isDefaultFilter ? null : currentFilter,
  });

  const newVehicleList = useMemo(() => {
    const modelList: Main.IModel[] = dataModels?.models;
    const variantList: Main.IVariant[] = dataVariants?.variants;
    const filteredvariantList: Main.IVariant[] = dataFilterVariants?.variants;

    return !isDefaultFilter && currentFilter
      ? filteredvariantList
      : modelList?.concat(variantList)?.sort((a: Main.IVariant, b: Main.IVariant) => a?.priority - b.priority);
  }, [dataModels, dataVariants, dataFilterVariants]);

  const handleMobileResult = () => {
    setSelectedModelFilterState(currentFilter);
    setVehicleList(newVehicleList);
    setIsModelFilterOpen(false);
  };

  const handleSlide = (range: number | [number, number]) => {
    setRangeValue(Array.isArray(range) ? range : [range, range]);
  };

  useEffect(() => {
    setSelectedFilter(initialSelectedFilter);
    setSelectedModelFilterState(convetFilterStateToStore(initialSelectedFilter));
  }, []);

  useEffect(() => {
    if (!!selectedModelFilterState) {
      const newSelectedFilter = convetFilterStoreToState(selectedModelFilterState);

      setSelectedFilter(newSelectedFilter);
      form.setFieldsValue(newSelectedFilter);
    }
  }, [selectedModelFilterState]);

  useEffect(() => {
    const isVehicleFetching = isModelFetching || isVariantFetching || isFilterVariantFetching;

    if (!!isVehicleFetching && !loading) setLoading(true);
    else if (!isVehicleFetching) setLoading(false);
  }, [isModelFetching, isVariantFetching, isFilterVariantFetching]);

  useEffect(() => {
    setCount(newVehicleList?.length);
  }, [newVehicleList]);

  useEffect(() => {
    if (device === 'M' && isModelFilterOpen) return;
    if (!newVehicleList) return;
    if (isDefaultFilter && (isModelFetching || isVariantFetching)) return;
    if (!isDefaultFilter && isFilterVariantFetching) return;

    setVehicleList(newVehicleList);
  }, [newVehicleList, isModelFetching, isVariantFetching, isFilterVariantFetching]);

  return (
    <div css={style} className={classNames('filter-content-container', { active: isModelFilterOpen })}>
      <Dim openDim={isModelFilterOpen} />
      <Form form={form} onChange={handleChangeFormValue}>
        <div className="filter-content-area">
          <div className="filter-content-top use-mobile">
            <div className="top-box">
              <div className="filter-title-box">
                <span>{t('SelectAModel.Filter')}</span>
              </div>
              <button onClick={closeFilter} type="button" className="btn-close">
                <i className="ic ic-close"></i>
              </button>
            </div>
          </div>
          <div className="filter-content-middle">
            <div className="filter-content-box">
              <div className="title-box">
                <span>{t('Label.FuelType')}</span>
              </div>
              <Form.Item name="fuelTypes" className="content-box" valuePropsName="values">
                <Checkbox.Group items={TEMP_FORM_DATA.fuelTypes} />
              </Form.Item>
            </div>
            <div className="filter-content-box">
              <div className="title-box">
                <span>{t('Label.Transmission')}</span>
              </div>{' '}
              <Form.Item name="transmitAutoYns" className="content-box" valuePropsName="values">
                <Checkbox.Group items={TEMP_FORM_DATA.transmitAutoYns} />
              </Form.Item>
            </div>
            <div className="filter-content-box">
              <div className="title-box">
                <span>{t('SelectAModel.Traction')}</span>
              </div>
              <Form.Item name="tractions" className="content-box" valuePropsName="values">
                <Checkbox.Group items={TEMP_FORM_DATA.tractions} />
              </Form.Item>
            </div>
            <div className="filter-content-box range-slider">
              <div className="title-box">
                <span>{t('SelectAModel.PriceRange')}</span>
              </div>
              <div className="valueBox-range-top ">
                <p className="value-range left">{priceWithComma(rangeValue[0])}</p>
                <div className="separator">~</div>
                <p className="value-range right">{priceWithComma(rangeValue[1])}</p>
              </div>
              <Form.Item className="content-box" name="price">
                <RangeSlider
                  step={PRICE_RANGE_STEP}
                  max={PRICE_RANGE_MAX}
                  min={PRICE_RANGE_MIN}
                  onSlide={handleSlide}
                />
              </Form.Item>
            </div>
          </div>
          <div className="filter-content-bottom use-mobile">
            <div className="button-box">
              <Button disabled={isDefaultFilter} onClick={handleClickReset} buttonType="secondary">
                {t('Label.Reset')}
              </Button>
              <Button disabled={!count} onClick={handleMobileResult}>
                {count ? count : '0'} {t('SelectAModel.Results')}
              </Button>
            </div>
          </div>
        </div>
      </Form>
      <div className="tag-area">
        <Tag.Group
          options={isDefaultFilterState ? [] : [...tagOptions, resetTagOption]}
          onClickDelete={onClickDelete}
        />
      </div>
    </div>
  );
}

export default ModelFilter;
